import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, Select } from '../../../components'
import { putFiltersProvisorio } from '../redux/comentariosActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.comentariosState)

  const nls = require(`../nls/${sistema.idioma}.json`)
  
  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  
  console.error(ciclo,'CICLO EM FILTERCICLE');
  console.error(ciclos,'CICLOSSSSS EM FILTERCICLE');



  const data = ciclo?.anoMesCiclo?.split('-') 
  const mes = nls.nomesMeses.find(nm=> Number(nm.id) === Number(data[1]))
  const ciclo1 = {...ciclo, mesAno:`${mes.name}-${data[0]}`, anoMes:`${data[0]}-${data[1]}`, ano: data[0], mes: data[1]}

  const ciclos1 = ciclos.map(ciclolido => {
    const data = ciclolido.anoMesCiclo.split('-');
    const mes = nls.nomesMeses.find(nm => Number(nm.id) === Number(data[1]));
    return {
      ...ciclolido,
      mesAno: `${mes.name}-${data[0]}`,
      anoMes: `${data[0]}-${data[1]}`,
      ano: data[0],
      mes: data[1]
    };
  });

  
  return (
   <div className='box-comentarios-search comentarios-search-ciclo'>
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos1}
         selected={ciclo1}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         optionLabel='mesAno'
         optionValue='anoMes'
      />
   </div>
  )
}
