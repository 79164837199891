import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Modal } from '../../../components'
import { modalClose } from '../../../layout/redux/layoutActions'
import { calculadoraCompraDeAtivos, CheckoutAtivosAvulsosXD, listarAtivos } from '../redux/minhasAssinaturasActions'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { ativos, avaliacoes } = useSelector(state=> state.minhasAssinaturasState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)
  const [form, setForm] = useState({avaliacoes: []})

  useEffect(()=> {
    if (statusModal === 'assinaturas-adquirir-avaliacoes') {
      // dispatch(listarAtivos(sistema.consumoConta, usuario.moedaIso4217))
      const hasChange = form.avaliacoes.find(a=> a.id === 6)
      const params = [
          {
            id: 6,
            descricao: nls.avaliacoesMensaisAdicionais,
            quantidadeDesejada: hasChange.id ? hasChange.quantidadeDesejada : 100,
            valor: 0,
          }
      ]
      if (!hasChange.id) {
        setForm({...form, avaliacoes: params})
      }
      dispatch(calculadoraCompraDeAtivos({ativos: params, moeda: usuario.moedaIso4217}))
    }
  }, [statusModal, sistema.idioma, sistema.consumoConta])
  
  useEffect(()=> {
    const listaAtivos = [
      {
        id: 6,
        descricao: nls.avaliacoesMensaisAdicionais,
        valor: 0,
        quantidadeDesejada: '100'
      }
    ]
    setForm({...form, avaliacoes: listaAtivos})
  }, [])
 
  const handleChange = e => {
    const params = [
        {
          id: 6,
          descricao: nls.avaliacoesMensaisAdicionais,
          quantidadeDesejada: e.quantidadeDesejada,
          valor: 0,
        }
    ]
    setForm({...form, avaliacoes: params})
    dispatch(calculadoraCompraDeAtivos({ativos: params, moeda: usuario.moedaIso4217}))
  }

  const handleCancelar = e => {
    dispatch(modalClose())
  }
  
  const handleSalvar = e => {
    const hasAtivos = form.avaliacoes?.filter(a=> a.quantidadeDesejada > 0)
    if (hasAtivos.length) {
      const params = {
        moeda: usuario.moedaIso4217,
        ativos: [...ativos, 
          ...form.avaliacoes
        ]
  
      }
      dispatch(CheckoutAtivosAvulsosXD(params, nlsPopup))
    }
  }
  
  
  return (
    <Modal
      name='assinaturas-adquirir-avaliacoes'
      title={nls[false? 'titleAlterarAvaliacoes' : 'titleAdquirirAvaliacoes']}
      open={statusModal === 'assinaturas-adquirir-avaliacoes' ? true : false}
      close={() => handleCancelar()}
      closeText={nls.btnFechar}
      actions={<>
        <Button
          color='default'
          onClick={()=> handleCancelar()}
        >{nls.cancelar}</Button>
        <Button
          color='primary'
          onClick={()=> handleSalvar({})}
        >{nls[false? 'editarAvaliacoes' : 'salvarAvaliacoes']}</Button>
      </>}

    >
      <div>
        <div className='avaliacoes-lista'>
          {form.avaliacoes?.map(e=> {
            return <div key={e.id} className='avaliacoes-lista-item'>
              <div className='avaliacoes-lista-item-info'>
                <strong>{e.descricao}</strong>
                {/* <small>{nls[e.id === 'avaliacoes-mensais-adicionais' ? 'avaliacoesAtuais' : 'valorAtual']}: {e.valor}</small> */}
              </div>
              <div className='avaliacoes-lista-item-input'>
                  <Input 
                    type='number'
                    value={e.quantidadeDesejada}
                    action={a=> handleChange({...e, quantidadeDesejada: a.value})}
                    min={100}
                    step="100"
                  />
              </div>
            </div>
          })}
        </div>
        <div className='avaliacoes-info'>
          <span>{nls.valorCobradoImediatamente}: <strong>{avaliacoes.valorAdicional}</strong> </span>
          <span>{nls.valorCobradoProxima}: <strong>{avaliacoes.valorTotal}</strong> </span>
        </div>
      </div>
    </Modal>
  )
}
